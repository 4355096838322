<template>
  <div class="breadcrumb">
    <span>
      <AtomsGlobalLink to="/">Rigad</AtomsGlobalLink>
    </span>
    <template v-for="(item, index) in props.segments?.sort((a, b) => b.level - a.level)">
      <span v-if="index > 0 || props.segments.length === 1 || props.showAllSegments">
        <AtomsGlobalLink v-if="(!props.lastSegmentStatic || index < props.segments?.length - 1) && item.url" :to="item.url">{{ item.name }}</AtomsGlobalLink>
        <span v-else>{{ item.name }}</span>
      </span>
    </template>
  </div>
</template>
<script setup>

const props = defineProps({
  segments: Array,
  lastSegmentStatic: Boolean,
  showAllSegments: Boolean
});

</script>
<style lang="postcss">
.breadcrumb {

  @apply my-6 desktop:my-8;

  a:hover {
    @apply underline;
  }

  span::after {
    content: '>';
    @apply mx-2;
  }

  span:last-of-type::after {
    display: none;
  }
}
</style>